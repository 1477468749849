import "../../styles/PageStyle/Home.css";
import Upperdown from "../../components/ToolComp/Upperdown";
import TwoColGrid from "../../components/ToolComp/twoColGrid";
// import ImageGallery from '../../components/ToolComp/PhotoGallery';

function Home() {
  return <div className="Home">
    <div className="main-section">
      <div className="section">
        <Upperdown img="img/logo-orange.png" title="About Emerald Valley Academy" context={["The mission of Emerald Valley Academy is to empower every student by harnessing technology to facilitate academic achievement.Our endeavor is to encourage students to reach their maximum academic potential, tailored to their individual learning styles and pace. Extensive research highlights the significant significance and worth of finishing secondary education, not solely for the individual but also for the broader community and stakeholders involved.", "Emerald Valley Academy remains dedicated to collaborating with students and parents alike, ensuring all learners accomplish their objectives towards obtaining an Ontario Secondary School Diploma (OSSD)."]} />
      </div>
      <div className="Vision section">
        <img class="image-divider"
          src="/img/img-divider.png" />
        <TwoColGrid img="img/bookshell.jpg" title="Vision" content={["At Emerald Valley Academy, we envision a dynamic and inclusive learning community that empowers students to excel academically, embrace their unique identities, and become responsible global citizens.", "Through innovative education and personalized guidance, we aspire to cultivate a passion for lifelong learning and inspire students to contribute positively to society."]} reversed={false} />
      </div>
      <div className="Mission section">
        <TwoColGrid img="img/library-2.jpg" title="Mission" content={["Our mission at Emerald Valley Academy is to provide a nurturing and challenging educational environment that fosters intellectual curiosity, critical thinking, and holistic growth. We are committed to delivering a well-rounded education that balances academic excellence with character development.", "By embracing diversity, promoting collaboration, and nurturing creativity, we aim to equip our students with the skills, values, and resilience needed to navigate an ever-changing world."]} reversed={true} />
      </div>
      <div className="photo-gallery-container section">
        <h2>Student Activities</h2>
        <p>Welcome to our gallery of student activities! Explore the diverse range of events that enhance our students' experiences, from academic challenges to cultural celebrations.</p>

        <blockquote>“Participating in these events has not only helped me grow academically but also personally. I’ve made lifelong friends through these activities!” - Jane Doe, Student</blockquote>

        {/* <ImageGallery /> */}

        <div className="event-cta">
          <p>Interested in joining an activity? <a href="/#/contact/contactus">Sign up here</a> to get involved and make the most of your student life!</p>
        </div>
      </div>
    </div>

  </div>;
}

export default Home;
