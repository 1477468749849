import React, { useState } from "react";
import "../../styles/CompStyle/Nav.css"
import { Link } from "react-router-dom";
export default function Nav() {
    const [isAcademicDropdownOpen, setIsAcademicDropdownOpen] = useState(false);
    const [isEvaResourcesDropdownOpen, setIsEvaResourcesDropdownOpen] = useState(false);
    const [isLoginDropdownOpen, setIsLoginDropdownOpen] = useState(false);
    const [isContactDropdownOpen, setIsContactDropdownOpen] = useState(false);
    const [isAdmissionDropdownOpen, setIsAdmissionDropdownOpen] = useState(false);

    const handleAcademicMouseEnter = () => {
        setIsAcademicDropdownOpen(true);
    };

    const handleAcademicMouseLeave = () => {
        setIsAcademicDropdownOpen(false);
    };

    const handleAdmissionMouseEnter = () => {
        setIsAdmissionDropdownOpen(true);
    };
    const handleAdmissionMouseLeave = () => {
        setIsAdmissionDropdownOpen(false);
    };

    const handleEvaResourcesMouseEnter = () => {
        setIsEvaResourcesDropdownOpen(true);
    };

    const handleEvaResourcesMouseLeave = () => {
        setIsEvaResourcesDropdownOpen(false);
    }

    const handleLoginMouseEnter = () => {
        setIsLoginDropdownOpen(true);
    };

    const handleLoginMouseLeave = () => {
        setIsLoginDropdownOpen(false);
    }

    const handleContactMouseEnter = () => {
        setIsContactDropdownOpen(true);
    };

    const handleContactMouseLeave = () => {
        setIsContactDropdownOpen(false);
    }
    return (
        <div className="Nav" id="nav">
            <ul className="nav-items">
                <li>
                    <Link to="/">
                        <span>Home</span>
                    </Link>
                </li>
                <li
                    onMouseEnter={handleAcademicMouseEnter}
                    onMouseLeave={handleAcademicMouseLeave}
                    className="dropdown-container"
                >
                    <Link>
                        <span className="dropdown-button">Academic</span>
                    </Link>

                    {isAcademicDropdownOpen && (
                        <ul className="dropdown-content">
                            <li>
                                <span style={{ color: "rgb(184, 134, 11)", fontWeight: "bold" }}>OSSD</span>
                                <ul className="sub-menu">
                                    <li>
                                        <Link to="/academic/grade9">Grade 9</Link>
                                    </li>
                                    <li>
                                        <Link to="/academic/grade10">Grade 10</Link>
                                    </li>
                                    <li>
                                        <Link to="/academic/grade11">Grade 11</Link>
                                    </li>
                                    <li>
                                        <Link to="/academic/grade12">Grade 12</Link>
                                    </li>
                                </ul>
                            </li>

                            <li>
                                <span style={{ color: "rgb(184, 134, 11)", fontWeight: "bold" }}>ESL</span>
                                <ul className="sub-menu">
                                    <li>
                                        <Link to="/academic/eslco">ESLCO</Link>
                                    </li>
                                    <li>
                                        <Link to="/academic/esldo">ESLDO</Link>
                                    </li>
                                    <li>
                                        <Link to="/academic/esleo">ESLEO</Link>
                                    </li>
                                </ul>
                            </li>

                        </ul>
                    )}
                </li>
                <li
                    onMouseEnter={handleAdmissionMouseEnter}
                    onMouseLeave={handleAdmissionMouseLeave}
                    className="dropdown-container"
                >
                    <Link>
                        <span className="dropdown-button">Admissions</span>
                    </Link>

                    {isAdmissionDropdownOpen && (
                        <ul className="dropdown-content">
                            {/* <li>
                                <Link to="/admission/application">Application</Link>
                            </li> */}
                            <li>
                                <Link to="/admission/calendar">Course Calendar</Link>
                            </li>
                            <li>
                                <a href="/EVA-SchoolCalender2024-2025(4).pdf" target="_blank" rel="noopener noreferrer">
                                    Year Calendar
                                </a>
                            </li>
                        </ul>
                    )}
                </li>
                <li
                    onMouseEnter={handleEvaResourcesMouseEnter}
                    onMouseLeave={handleEvaResourcesMouseLeave}
                    className="dropdown-container"
                >
                    <Link>
                        <span className="dropdown-button">Resources</span>
                    </Link>

                    {isEvaResourcesDropdownOpen && (
                        <ul className="dropdown-content">
                            <li>
                                <a href="https://data.ontario.ca/dataset/private-school-contact-information" target="_blank">Ontario Private School List</a>
                                    </li>
                                    <li>
                                <a href="https://www.canada.ca/en/immigration-refugees-citizenship/services/study-canada/study-permit.html" target="_blank">CIC Study Permit Process</a>
                                    </li>
                                    <li>
                                        <a href="https://www.univcan.ca/universities/facts-and-stats/enrolment-by-university/" target="_blank">Enrolment by University</a>
                                    </li>
                                    <li>
                                <a href="https://www.citationmachine.net/mla/cite-a-website" target="_blank">MLA Citations Tool</a>
                                    </li>
                                    <li>
                                <a href="https://www.statcan.gc.ca/en/subjects-start/labour_" target="_blank">Canada Employment Statistics</a>
                            </li>
                            <li>
                                <Link to="/resources/university">OSSD Recognition</Link>
                            </li>
                        </ul>
                    )}
                </li>
                <li onMouseEnter={handleLoginMouseEnter}
                    onMouseLeave={handleLoginMouseLeave}
                    className="dropdown-container">
                    <Link>
                        <span className="dropdown-button">Students</span>
                    </Link>
                    {isLoginDropdownOpen && (
                        <ul className="dropdown-content">
                            <li>
                                <a href="https://eva.moodlecloud.com" target="_blank">LMS: Moodle</a>
                            </li>
                            <li>
                                <a href="https://app.myblueprint.ca/" target="_blank">SIS: Myblueprint</a>
                            </li>
                            <li>
                                <Link to="/login/verify">Student Verification</Link>
                            </li>
                        </ul>
                    )}

                </li>
                <li
                    onMouseEnter={handleContactMouseEnter}
                    onMouseLeave={handleContactMouseLeave}
                    className="dropdown-container"
                >
                    <Link>
                        <span className="dropdown-button">Contact</span>
                    </Link>

                    {isContactDropdownOpen && (
                        <ul className="dropdown-content">
                            <li>
                                <Link to="/contact/contactus">Contact Us</Link>
                            </li>
                            <li>
                                <Link to="/contact/joinus">Join Us</Link>
                            </li>
                        </ul>
                    )}
                </li>

            </ul>

        </div>
    );
}